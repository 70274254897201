module.exports = [{
      plugin: require('/builds/Coderwelsch/www.coderwelsch.com/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":900},
    },{
      plugin: require('/builds/Coderwelsch/www.coderwelsch.com/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"coderwelsch.com","short_name":"Coderwelsch","start_url":"/","background_color":"#F21D41","theme_color":"#F21D41","display":"standalone","icon":"src/assets/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"839725e3a4ddc5a37ce1aa91de56a97f"},
    },{
      plugin: require('/builds/Coderwelsch/www.coderwelsch.com/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/builds/Coderwelsch/www.coderwelsch.com/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
